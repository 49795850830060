import React from 'react';
import Landing from '../components/Landing/Landing';
import Localize from '../constants/Localize.json';
import { LangEnum } from '../types';

const Ru = () => (
  <Landing
    lang={LangEnum.ru}
    title={Localize[LangEnum.ru].title}
    keywords={Localize[LangEnum.ru].keywords}
    description={Localize[LangEnum.ru].description}
  />
);

export default Ru;
